export const firebaseConfig = {
  // apiKey: "AIzaSyB5wfHHLWkrE1Wv-Dw6GySzTg-iboPsptg",
  // authDomain: "testing-ee80d.firebaseapp.com",
  // databaseURL: "https://testing-ee80d.firebaseio.com",
  // projectId: "testing-ee80d",
  // storageBucket: "testing-ee80d.appspot.com",
  // messagingSenderId: "609001151337",
  // appId: "1:609001151337:web:1b32014565d04c8a47bbf8",
  // measurementId: "G-2819NRELDC"
  apiKey: 'AIzaSyAao9E5x5O0jK6MULRUpLKQHu4Uk_lOASA',
  authDomain: 'ally-assist-final.firebaseapp.com',
  databaseURL: 'https://ally-assist-final.firebaseio.com',
  projectId: 'ally-assist-final',
  storageBucket: 'ally-assist-final.appspot.com',
  messagingSenderId: '344596013700',
};
