import React, { useEffect } from 'react';
import Home from './Home';

import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

function AppSwitch() {
  let location = useLocation();
  useEffect(() => {
    if(location && location.pathname) {
      window.gtag('config', 'GA_MEASUREMENT_ID', {
        'page_path': location.pathname,
      });
    }
  }, [location]);

  return (
    <>
    <Switch>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
    </>
  );
}

export default AppSwitch;