import React from 'react';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import { Controller } from "react-hook-form";
import InputMask from 'react-input-mask';
import ReactPhoneInput from 'react-phone-number-input/input'
import cx from 'classnames';
import { fieldTypes } from './Constants';

export const TextInput = ({...props}) => {
    const inputId = uniqueId('text_');
    return (
      <Controller
        name={props.fieldName}
        control={props.control}
        defaultValue=""
        rules={props.rules}
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => (
          <>
            <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 mt-3 mb-1">{props.label}</label>
            <div className="mt-1 mb-3 relative rounded-md shadow-sm">
              {props.multiline &&
                <textarea 
                  name={name}
                  rows={props.rows? props.rows : 5}
                  id={inputId}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  className={cx({
                    'focus:ring-indigo-500': !invalid,
                    'focus:border-indigo-500': !invalid,
                    'block': true,
                    'w-full': true,
                    'sm:text-sm': true,
                    'rounded-md': true,
                    'border-gray-300': true,
                    'focus:border-red-500': invalid,
                    'focus:ring-red-500': invalid,
                    'border-red-500': invalid
                  })}
                  placeholder={props.placeholder}
                />
              }
              {!props.multiline &&
                <input 
                  type="text"
                  name={name}
                  id={inputId}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  className={cx({
                    'focus:ring-indigo-500': !invalid,
                    'focus:border-indigo-500': !invalid,
                    'block': true,
                    'w-full': true,
                    'sm:text-sm': true,
                    'rounded-md': true,
                    'border-gray-300': true,
                    'focus:border-red-500': invalid,
                    'focus:ring-red-500': invalid,
                    'border-red-500': invalid
                  })}
                  placeholder={props.placeholder} />
                }
          </div>
          {invalid && <span className="block text-xs text-red-500 mt-2 mb-2">Please enter a valid value</span>}
        </>
        )}
      />
    )
  }
  
export const PhoneInput = ({...props}) => {
    const inputId = uniqueId('phone_');
    return (
      <Controller
        name={props.fieldName}
        control={props.control}
        defaultValue=""
        rules={props.rules}
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => (
          <>
            <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 mt-3 mb-1">{props.label}</label>
            <div className="mt-1 mb-3 relative rounded-md shadow-sm">
              <ReactPhoneInput
                type="text"
                defaultCountry="AU"
                name={name}
                id={inputId}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                className={cx({
                  'focus:ring-indigo-500': !invalid,
                  'focus:border-indigo-500': !invalid,
                  'block': true,
                  'w-full': true,
                  'sm:text-sm': true,
                  'rounded-md': true,
                  'border-gray-300': true,
                  'focus:border-red-500': invalid,
                  'focus:ring-red-500': invalid,
                  'border-red-500': invalid
                })}
                placeholder={props.placeholder}
              />
          </div>
          {invalid && <span className="block text-xs text-red-500 mt-2 mb-2">Please enter a valid phone number</span>}
        </>
        )}
      />
    )
  }
  
export const MutliSelectInput = ({...props}) => {
    const inputId = uniqueId('mutliselect_');
    const optionLabel = props.optionLabel ? props.optionLabel : 'label';
  
    return (
      <Controller
        name={props.fieldName}
        control={props.control}
        defaultValue={[]}
        rules={{required: true}}
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => {
          const setOption = (e, option) => {
            const selectedIndex = value.findIndex(setOption => isEqual(option, setOption));
  
            if(selectedIndex > -1) {
              value.splice(selectedIndex, 1);
              const updatedValues = [...value];
              onChange(updatedValues);
            } else {
              const updatedValues = [...value, option];
              onChange(updatedValues);
            }
          };
    
          return (
            <>
              <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 mt-3 mb-1">
                {props.label}
              </label>
              <div id={inputId} className="mt-1 mb-3" role="group" aria-labelledby={props.id}>
                {props.options.map((option, index) => {
                  const selectedItem = value ? value.findIndex(setOption => isEqual(option, setOption)) > -1 : false;
                  
                  return (
                    <label className="flex w-full items-center mt-3">
                      <input
                        type="checkbox"
                        name={name}
                        ref={ref}
                        value={value}
                        className="form-checkbox h-4 w-4 text-indigo-600 rounded"
                        aria-checked={selectedItem}
                        onChange={(e) => setOption(e, option)}
                        onBlur={onBlur}
                        checked={selectedItem} />
                      <span className="mr-2 ml-2 text-sm">{option[optionLabel]}</span>
                    </label>
                  )}
                )}
              </div>
              {invalid && <span className="block text-xs text-red-500 mt-2 mb-2">Please enter a valid value</span>}
            </>
          )
          }
        }>
      </Controller>
    );
  }
  
export const SelectInput = ({...props}) => {
  const optionValue = props.optionValue ? props.optionValue : 'value';
  const optionLabel = props.optionLabel ? props.optionLabel : 'label';
  const inputId = uniqueId('select_');

  return (
      <Controller
        name={props.fieldName}
        control={props.control}
        defaultValue=""
        rules={props.rules}
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => (
          <>
            <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 mt-3 mb-1">{props.label}</label>
            <div className="mt-1 mb-3 relative rounded-md shadow-sm">
              <select 
                id={inputId}
                value={value}
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                className={cx({
                  'focus:ring-indigo-500': !invalid,
                  'focus:border-indigo-500': !invalid,
                  'block': true,
                  'w-full': true,
                  'sm:text-sm': true,
                  'rounded-md': true,
                  'border-gray-300': true,
                  'focus:border-red-500': invalid,
                  'focus:ring-red-500': invalid,
                  'border-red-500': invalid
                })}
              >
                <option value="">{props.placeholderText}</option>
                {props.options.map((option, i) => {
                  return (
                    <option key={i} value={option[optionValue]}>{option[optionLabel]}</option>
                  )
                })}
              </select>
            </div>
            {invalid && <span className="block text-xs text-red-500 mt-2 mb-2">Please select a valid value</span>}
          </>
        )}>
      </Controller>
  )
}
  
export const RadioInput = ({...props}) => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue=""
      rules={props.rules}
      render={(
        { onChange, onBlur, name },
        { invalid }
      ) => {
        return (
          <>
          <label className="block text-sm font-medium text-gray-700 mt-3 mb-1">{props.label}</label>
          <div className="flex flex-row mt-1 mb-3">
            {props.options.map((field, i) => {
              const inputId = uniqueId("radio_");
              return (
                <div className="inline-flex items-center mr-5" key={i}>
                  <input id={inputId}
                    name={name}
                    type="radio"
                    value={field.value}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={cx({
                      'focus:ring-indigo-500': !invalid,
                      'focus:ring-red-500': invalid,
                      'border-red-500': invalid,
                      'h-4': true,
                      'w-4': true,
                      'text-indigo-600': true,
                      'border-gray-300': true,
                    })}
                  />
                  <label htmlFor={inputId} className="ml-3">
                    <span className="block text-sm font-medium text-gray-700">{field.label}</span>
                  </label>
                </div>
            )})
          }
        </div>
        {invalid && <span className="block text-xs text-red-500 mt-2 mb-2">Please enter a valid value</span>}
        </>
      )}}
    />
  )
}
  
export const MaskInput = ({...props}) => {
  const inputId = uniqueId('mask_');

  function maskInputFieldType(type) {
    switch(type) {
      case fieldTypes.DATE:
        return "99/99/9999";
      case fieldTypes.POSTCODE:
      default: 
        return '9999';
    }
  }

  return (
    <>
      <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 mt-3 mb-1">{props.label}</label>
      <Controller
        name={props.fieldName}
        control={props.control}
        defaultValue=""
        rules={props.rules}
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => ( 
        <>
            <InputMask mask={maskInputFieldType(props.fieldType)}
              name={props.fieldName}
              value={value}
              maskChar=""
              onBlur={onBlur}
              onChange={onChange}
            >
              {(inputProps) => (
                <input
                  name={name}
                  id={inputId}
                  placeholder={props.placeholder}
                  {...inputProps}
                  type="text"
                  ref={ref}
                  value={value}
                  className={cx({
                    'focus:ring-indigo-500': !invalid,
                    'focus:border-indigo-500': !invalid,
                    'block': true,
                    'w-full': true,
                    'sm:text-sm': true,
                    'rounded-md': true,
                    'border-gray-300': true,
                    'focus:border-red-500': invalid,
                    'focus:ring-red-500': invalid,
                    'border-red-500': invalid
                  })}
                />
              )}
            </InputMask>
            {invalid && <span className="block text-xs text-red-500 mt-2 mb-2">Please enter a valid value</span>}
          </>
        )}
      />
    </>
  )
}