export const fieldTypes = {
  DATE: 'date',
  POSTCODE: 'postcode',
  PHONE: 'phone',
};

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
