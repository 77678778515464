import React from 'react';
import './App.css';
import Logo from './assets/fora-nobg.png';
import NDISLogo from './assets/NDIS.png';
import AppSwitch from './Components/AppSwitch';
import {
  BrowserRouter as Router,
} from "react-router-dom";


function App() {
  return (
    <>
      <header className="container mx-auto flex flex-row p-5" role="banner">
        <div className="flex flex-grow items-center">
          <div className="flex items-center">
            <a href="https://www.foratherapy.com" rel="noreferrer" className="flex flex-row items-center" target="_blank">
              <img src={Logo} style={{width: '60px'}} alt="Fora" />
            </a>
          </div>
        </div>
        <img src={NDISLogo} className="self-end" style={{maxHeight: 50}} alt="Registered NDIS Profile" />
      </header>
      <div className="container mx-auto px-5">
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="https://www.foratherapy.com" className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                  <span className="sr-only">Home</span>
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <a href="https://refer.foratherapy.com" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Make a Referral</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="container mx-auto">
        <Router>
          <AppSwitch />
        </Router>
      </div>
      <footer className="p-5 text-center">
        <p>&copy; Fora</p>
      </footer>
    </>
  );
}

export default App;