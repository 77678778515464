import React, { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import JourneyImage from '../assets/journey.svg';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { firebaseConfig } from '../firebase.config';
import { useToasts } from 'react-toast-notifications'
import ReCAPTCHA from 'react-google-recaptcha';
import Confetti from 'react-dom-confetti';
import isEmail from 'validator/lib/isEmail';
import languages from '../data/languages.json';
import { TextInput, SelectInput, MutliSelectInput, MaskInput, RadioInput, PhoneInput } from './FormFields';
import { fieldTypes, yesNoOptions } from './Constants';

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function Home() {
  const { addToast } = useToasts()
  const { handleSubmit, reset, control, watch } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const recaptchaRef: any = useRef({});

  const onSubmit = async(data) => {
    window.gtag('event', 'submit_referral', {
      'event_category': 'Referral',
      'event_label': 'Referral Submission',
      'value': data,
    });

    await recaptchaRef.current.executeAsync();

    let referralSubmission = {} as any;
    let updatedData = {...data};
    referralSubmission.createdDate = new Date();

    if(updatedData.ahpDiscipline && updatedData.ahpDiscipline.length > 0) {
      updatedData.ahpDiscipline = updatedData.ahpDiscipline.map(discipline => discipline.label).join(", ");
    }

    if(updatedData.clientSeeking && updatedData.clientSeeking.length > 0) {
      updatedData.clientSeeking = updatedData.clientSeeking.map(discipline => discipline.label).join(", ");
    }

    referralSubmission.to = 'info@foratherapy.com';
    referralSubmission.from = '"Fora" <info@foratherapy.com>';
    referralSubmission.template = {
      name: 'referral-email',
      data: updatedData,
    };

    try {
      await db.collection('referrals/').add(referralSubmission);
      addToast(`Thank you for your referral; we will be in touch!`, { appearance: 'success'});
      setFormSubmitted(true);
      reset();
      setFormSubmitted(false);
    } catch(e) {
      debugger;
      addToast(`There was an error processsing your referral`, { appearance: 'error'});
      console.error(e);
    }
  }

  const referValues = {
    AHP: 'ahp',
    SC: 'sc',
    LAC: 'lac',
    FAMILY_FRIEND: 'family_or_friend',
  }

  const relation  = [
    {label: 'Allied Health Professional', value:  referValues.AHP },
    {label: 'Support Coordinator', value: referValues.SC },
    {label: 'Local Area Coordinator', value: referValues.LAC },
    {label: 'Family or Friend', value: referValues.FAMILY_FRIEND}
  ];

  const alliedHealthDisciplines  = [
    {label: 'Physiotherapy', value: 'ahp'},
    {label: 'Occupational Therapy', value: 'ot'},
    {label: 'Speech Therapy', value: 'sc'},
    {label: 'Psychology', value: 'lac'},
    {label: 'Positive Behaviour Support', value: 'positive_behavior_support'},
    {label: 'Other', value: 'other'}
  ];

  const clientSeekingOptions  = [
    {label: 'Allied Health Assistant', value: 'aha'},
    {label: 'Speech Pathologist', value: 'sp'},
    {label: 'Occupational Therapist', value: 'ot'},
    {label: 'Physiotherapist', value: 'pt'},
    {label: 'Other', value: 'other'}
  ];

  const callOptions  = [
    {label: 'In Person', value: 'In Person'},
    {label: 'Video Chat (Zoom)', value: 'Video Chat (Zoom)'},
  ];

  const fundingBodies  = [
    {label: "Privately Funded", value: "Privately Funded"},
    {label: 'NDIS', value: 'NDIS'},
    {label: 'TAC', value: 'TAC'},
    {label: 'WorkSafe', value: 'WorkSafe'},
    {label: 'Other', value: 'Other'}
  ];

  const genders = [
    {label: 'Female', value: 'Female'},
    {label: 'Male', value: 'Male'},
    {label: 'Non-binary', value: 'Non-binary'},
    {label: 'Transgender', value: 'Transgender'},
    {label: 'Intersex', value: 'Intersex'},
    {label: 'Gender non-conforming', value: 'Gender non-conforming'},
    {label: 'Other', value: 'Other'},
  ];

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  const relationType = watch("relation");
  const clientGender = watch("clientGender");
  const ahpDiscipline = watch("ahpDiscipline")?.map(discipline => discipline.label);
  const clientSeeking = watch("clientSeeking")?.map(discipline => discipline.label);
  const interpereterRequired = watch("interpereterRequired");
  const fundingBody = watch("clientFundingBody");

  return (
    <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4" role="main">
          <article className="p-5 text-left" >
              <h2 className="text-3xl font-bold mb-2">Make a Referral</h2>
              <div className="prose mr-0 max-w-none">
                <p>We’re thrilled you’re part of our community.</p>
                <p>If you are happy with Fora, we’d love for you to spread the word about us with your network. Here is what happens after you make a referral:</p>
                <img src={JourneyImage} className="mt-5" alt="Fora Referal Journey" />
                <div className="sr-only">
                  <h2>Fora Referral Journey</h2>
                  <ol>
                    <li>Referral - Fora recieves a referral for the client.</li>
                    <li>Client Sign-up - Client Signs up with Fora.</li>
                    <li>AHP Contact - we ask the AHP if they're happy to work with our Therapy Assistant</li>
                    <li>TA Selection - we help the client choose a therapy assistant</li>
                    <li>Team Meeting - meeting between AHP, TA, and Client to discuss goals and set expectations.</li>
                    <li>Check in - TA will check in with you as often as you recommend</li>
                  </ol>
                </div>
                <p>When you refer a person seeking assistance to Fora, they can choose and meet with a therapy assistant at <strong>absolutely no cost</strong>. Our community only starts paying when they feel they’ve found somebody that’s right for them.</p>
                <p>If you know somebody that might benefit from using a therapy assistant, fill out this form and we’ll aim to get back to you by the next business day.</p>
              </div>
          </article>
          <div className="p-5">
              <form onSubmit={handleSubmit(onSubmit)}>            
                <h1 className="text-2xl font-bold mb-1">Your Details</h1>
                <SelectInput
                  label="What relation are you to the person seeking assistance?"
                  placeholderText="Please Select"
                  fieldName="relation"
                  control={control}
                  options={relation}
                  rules={{required: true}}     
                />
                {relationType === referValues.AHP &&
                  <>
                    <MutliSelectInput
                      label="What’s your allied health discipline?"
                      control={control}
                      placeholderText="Please Select"
                      fieldName="ahpDiscipline"
                      options={alliedHealthDisciplines}
                    />
                    {ahpDiscipline && ahpDiscipline.includes('Other') &&
                      <TextInput
                        label="Please enter your allied health discipline"
                        fieldName="ahpDisciplineOther"
                        placeholder="e.g Exercise Physiologist"
                        rules={{required: true}}
                        control={control}
                      />
                    }
                    <RadioInput
                      fieldName="ahpRegistered"
                      options={yesNoOptions}
                      control={control}
                      rules={{required: true}}
                      label="Are you registered with AHPRA or SPA?"
                    />
                  </>
                }
                {relationType && relationType !== referValues.FAMILY_FRIEND && 
                  <TextInput
                    label="Which organisation (if any) do you represent?"
                    fieldName="orgRepresent"
                    placeholder="Please enter an organisation"
                    rules={{required: true}}
                    control={control}
                  />
                }
                <TextInput
                  label="Full Name"
                  fieldName="refereeName"
                  placeholder="Please enter your full name"
                  control={control}
                  rules={{required: true}}
                  />
                <TextInput
                  label="Email"
                  fieldName="refereeEmail"
                  placeholder="Please enter your email address"
                  rules={{
                    required: true,
                    validate: (input) => isEmail(input), // returns true if valid
                  }}
                  control={control}
                />
                <PhoneInput
                  label="Phone Number"
                  fieldName="refereePhone"
                  placeholder="0400 000 000"
                  control={control}
                  rules={{
                    required: true,
                    validate: isValidPhoneNumber,
                  }}
                />
                <h1 className="text-2xl font-bold mb-1 mt-8">Contact Person</h1>
                <p className="mb-4">Who is the person seeking assistance’s best contact person? <br /><em className="text-sm">This is the person we will contact on a regular basis regarding cancellations, schedules TA selection etc. This is usually a parent, a close family member or the person seeking assistance themselves</em></p>
                <TextInput
                  label="Full Name"
                  fieldName="contactName"
                  placeholder="What is their full name?"
                  control={control}
                  rules={{ required: true }}
                />
                <TextInput
                  label="Email"
                  fieldName="contactEmail"
                  placeholder="What is their email address?"
                  rules={{
                    required: true,
                    validate: (input) => isEmail(input), // returns true if valid
                  }}
                  control={control}
                />
                <PhoneInput
                  label="Phone Number"
                  fieldName="contactPhone"
                  placeholder="0400 000 000"
                  control={control}
                  rules={{
                    required: true,
                    validate: isValidPhoneNumber,
                  }}
                />
                <TextInput
                  label="Relation to the person seeking assistance"
                  fieldName="contactRelation"
                  placeholder="e.g - parent, etc"
                  rules={{required: true}}
                  control={control}
                />
                <RadioInput
                  fieldName="interpereterRequired"
                  options={yesNoOptions}
                  control={control}
                  rules={{required: true}}
                  label="Interpeter Required"
                />
                {interpereterRequired === 'true' &&
                  <SelectInput
                    label="Language"
                    fieldName="interpereterLanguage"
                    control={control}
                    placeholderText="None"
                    options={Object.values(languages)}
                    placeholder="Please enter a value"
                    optionLabel="name"
                    optionValue="name"
                    rules={{required: true}}
                  />
                }
                {relationType !== referValues.FAMILY_FRIEND &&
                  <>
                    <h1 className="text-2xl font-bold mb-1 mt-8">What are the details of the person seeking assistance?</h1>
                    <TextInput
                      label="Full Name"
                      fieldName="clientFullName"
                      placeholder="Please enter the name of the person seeking assistance"
                      control={control}
                      rules={{ required: true }}
                    />
                    <MutliSelectInput
                      label="The person is seeking to work with:"
                      control={control}
                      placeholderText="Please Select"
                      fieldName="clientSeeking"
                      options={clientSeekingOptions}
                      rules={{required: true}}
                    />
                    {clientSeeking && clientSeeking.includes('Other') &&
                      <TextInput
                        label="Please describe"
                        fieldName="clientSeekingOther"
                        placeholder="e.g Exercise Physiologist"
                        rules={{required: true}}
                        control={control}
                      />
                    }
                    <MaskInput
                      label="Date of Birth"
                      fieldName="clientDateOfBirth"
                      fieldType={fieldTypes.DATE}
                      placeholder="dd/mm/yyyy"
                      control={control}
                      rules={{ required: true, minLength: 10}}
                    />
                    <SelectInput
                      label="Gender"
                      placeholderText="Please Select"
                      control={control}
                      fieldName="clientGender"
                      options={genders}
                      rules={{ required: true }}
                    />
                    {clientGender === 'Other' &&
                      <TextInput
                        label="Gender"
                        placeholder="Please specify your preferred gender"
                        control={control}
                        rules={{ required: true }}
                        fieldName="clientOtherGender"
                        options={genders}
                      />
                    }
                    <TextInput
                      label="Suburb"
                      fieldName="clientSuburb"
                      placeholder="e.g - Clayton"
                      control={control}
                      rules={{ required: true }}
                    />
                    <MaskInput
                      label="Post Code"
                      fieldName="clientPostCode"
                      fieldType={fieldTypes.POSTCODE}
                      placeholder="e.g. 3000"
                      control={control}
                      rules={{ required: true }}
                    />
                    <SelectInput
                      label="Funding Body"
                      placeholderText="Please Select"
                      control={control}
                      fieldName="clientFundingBody"
                      options={fundingBodies}
                      rules={{ required: true }}
                    />
                    {fundingBody === 'Other' &&
                      <TextInput
                        label="Please enter a funding body"
                        fieldName="clientOtherFundingBody"
                        placeholderText=""
                        control={control}
                        rules={{ required: true }}
                      />
                    }
                    <TextInput
                      multiline
                      label="Tell us a little about the person seeking assistance"
                      placeholder="Let us know us who they are, their hobbies, interests and anything else that might be important"
                      fieldName="clientInfo"
                      rules={{required: true}}
                      control={control}
                    />
                  </>
                }
                {(relationType !== referValues.FAMILY_FRIEND) && 
                  <div className="mt-6">
                    <p className="text-sm"><em>Once we’ve found an assistant, we’ll organise a Team Meeting together with the person seeking assistance, the new TA and the client’s AHP. What kind of meeting is most appropriate in this case?</em></p>
                    <SelectInput
                      control={control}
                      label="Meeting Preference"
                      fieldName="clientMeetingPreference"
                      placeholderText="None"
                      options={callOptions}
                      rules={{ required: true }}
                      placeholder="Please enter a value"
                    />
                </div>  
                }
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                />
                <Confetti active={formSubmitted} config={confettiConfig} />
                <button type="submit" className="p-2 text-white bg-blue-500 rounded mt-5">Submit</button>
              </form>
          </div>
      </div>
    </>
  );
}

export default Home;